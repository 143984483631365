import React, {Component} from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Ajay_pic from "../images/team/Ajay.png"
import Ankit_pic from "../images/team/Ankit-A.png"
import Anushkaa_pic from "../images/team/anushkaa.png"
import Aseem_pic from "../images/team/Aseem.png"
import Ashish_pic from "../images/team/Ashish.png"
import Ashwani_pic from "../images/team/Ashwani.png"
import Avneet_pic from "../images/team/Avneet.png"
import Carlos_pic from "../images/team/Carlos.png"
import Chatur_pic from "../images/team/Chatur.png"
import DalbirSingh_pic from "../images/team/Dalbir-Singh.png"
import Deep_pic from "../images/team/Deep.png"
import Deepanshu_pic from "../images/team/Deepanshu.png"
import Girish_pic from "../images/team/Girish.png"
import Gopal_pic from "../images/team/Gopal.png"
import Gopalsingh_pic from "../images/team/Gopal-Singh.png"
import Gurbir_pic from "../images/team/Gurbir.png"
import Jyoti_pic from "../images/team/Jyoti.png"
import Kamal_pic from "../images/team/Kamal.png"
import Ketaky_pic from "../images/team/Ketaky.png"
import Lalit_pic from "../images/team/lalit.png"
import Manik_pic from "../images/team/Manik.png"
import Manju_pic from "../images/team/Manju.png"
import Pancham_pic from "../images/team/Pancham.png"
import Parveen_pic from "../images/team/Parveen.png"
import Piyush_pic from "../images/team/Piyush.png"
import Pooja_pic from "../images/team/pooja.png"
import Prince_pic from "../images/team/Prince.png"
import Rajiv_pic from "../images/team/Rajiv.png"
import Rajnish_pic from "../images/team/Rajnish.png"
import Ravi_pic from "../images/team/Ravi.png"
import Ravinder_pic from "../images/team/ravinder.png"
import Riti_pic from "../images/team/Riti.png"
import Rashmi_pic from "../images/team/Rashmi.png"
import Sanjana_pic from "../images/team/Sanjana.png"
import Sarthak_pic from "../images/team/Sarthak.png"
import Shubham_pic from "../images/team/Shubham.png"
import Sumit_pic from "../images/team/Sumit.png"
import Sureshrawat_pic from "../images/team/SureshRawat-Office-support.png"
import Varun_T_pic from "../images/team/Varun-T.png"
import Vivek_pic from "../images/team/Vivek.png"
import vivek_linux_pic from "../images/team/vivek_linux.png"
import Yash_pic from "../images/team/Yash.png"
import {Helmet} from "react-helmet/es/Helmet";


export default class Container extends Component {

  render() {

    return (
      <Layout>
        <Helmet>
          <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
          <meta name="description"
                content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
              <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
                <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
                <meta name="description"
                      content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
                <meta property="og:site_name" content="Cueblocks"/>
                <meta property="fb:app_id" content="289086684439915"/>
                <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
                <meta property="og:title"
                      content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
                <meta property="og:description"
                      content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
                <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:description"
                      content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
                <meta name="twitter:title"
                      content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
                <meta name="twitter:site" content="@cueblocks"/>
                <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <section className="ser_sec_row team-page ">
          <div className="container">
            <div className="main-heading">
              <h2 className="heading_main">MEET OUR TEAM</h2>
              <p>
                Since 2005 CueBlocks has delivered only because of our extraordinary people! Here are the Cuebies who work their magic in eCommerce and allow us to serve our clients.
              </p>
            </div>

            <div className="team-members">
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ajay_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ajay Kumar</h4>
                </div>
                <div className="member-pro">Office Support</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ankit_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ankit Ashri</h4>
                </div>
                <div className="member-pro">Team Lead - Quality Analysis</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Anushkaa_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Anushkaa Dang</h4>
                </div>
                <div className="member-pro">Copywriter</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Aseem_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Aseem Shah</h4>
                </div>
                <div className="member-pro">
                  Division Head, Digital Advertising &amp; Analytics
                </div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ashish_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ashish Kumar</h4>
                </div>
                <div className="member-pro">Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ashwani_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ashwani Bhatia</h4>
                </div>
                <div className="member-pro">Manager - Operations</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Avneet_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Avneet Jirman</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Carlos_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Carlos</h4>
                </div>
                <div className="member-pro">Chief Cuddles Officer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Chatur_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Chatur</h4>
                </div>
                <div className="member-pro">Chief Happiness Officer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={DalbirSingh_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Dalbir Singh</h4>
                </div>
                <div className="member-pro">UI Designer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Deep_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Deep Sharma</h4>
                </div>
                <div className="member-pro">Accountant</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Deepanshu_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Deepanshu Bhatia</h4>
                </div>
                <div className="member-pro">Organic Search Specialist</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Girish_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Girish Tiwari</h4>
                </div>
                <div className="member-pro">
                  Team Lead - eCommerce Development
                </div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gopal_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Gopal Sharma</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gopalsingh_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Gopal Singh</h4>
                </div>
                <div className="member-pro">Chef</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Gurbir_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Gurbir Singh</h4>
                </div>
                <div className="member-pro">Organic Search Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Jyoti_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Jyoti</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Kamal_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Kamal Singh</h4>
                </div>
                <div className="member-pro">Office Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ketaky_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ketaky Sharma</h4>
                </div>
                <div className="member-pro">Manager - Human Resources</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Lalit_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Lalit Dhidwal</h4>
                </div>
                <div className="member-pro">Linux System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Manik_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Manik Walia</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Manju_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Manju Chauhan</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Pancham_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Pancham Prashar</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Parveen_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Parveen Kumar</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Piyush_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Piyush Walia</h4>
                </div>
                <div className="member-pro">Team Lead - UI/ UX</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Pooja_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Pooja Rathore</h4>
                </div>
                <div className="member-pro">Talent Acquisition Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Prince_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Prince Antil</h4>
                </div>
                <div className="member-pro">
                  Division Head - eCommerce Development
                </div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajiv_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Rajiv Singha</h4>
                </div>
                <div className="member-pro">Division Head - Organic Search</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Rajnish_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Rajnish Kumar</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Ravi_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ravi Bhushan Sharma</h4>
                </div>
                <div className="member-pro">Manager - Business Development</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Ravinder_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Ravinder Singh</h4>
                </div>
                <div className="member-pro">Front End Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Riti_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Riti Aggarwal</h4>
                </div>
                <div className="member-pro">Division Head - Development</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Rashmi_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Rashmi Singla</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Sanjana_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Sanjana prabhakar</h4>
                </div>
                <div className="member-pro">UI Designer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sarthak_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Sarthak Aggarwal</h4>
                </div>
                <div className="member-pro">Co-Founder</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Shubham_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Shubham Jain</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Sumit_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Sumit Sharma</h4>
                </div>
                <div className="member-pro">Web Developer</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Sureshrawat_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Suresh Rawat</h4>
                </div>
                <div className="member-pro">Office support</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Varun_T_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Varun Takkar</h4>
                </div>
                <div className="member-pro">Quality Analyst</div>
              </div>

              <div className="member-sec">
                <div className="member-img">
                  <img src={Vivek_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Vivek Puri</h4>
                </div>
                <div className="member-pro">Digital Advertising Specialist</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={vivek_linux_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Vivek Bhatti</h4>
                </div>
                <div className="member-pro">Linux System Administrator</div>
              </div>
              <div className="member-sec">
                <div className="member-img">
                  <img src={Yash_pic} alt="Cue Blocks" />
                </div>
                <div className="member-name">
                  <h4>Yash Pal</h4>
                </div>
                <div className="member-pro">
                  Division Head, Digital Marketing (CSE)
                </div>
              </div>
            </div>
            <div className="main-heading btmm-team">
              <h2 className="heading_main">JOIN OUR TEAM</h2>
              <p>
                We’re always on the lookout for smart, purposeful, driven, and zany people to be part of our growing team.
                 Check out the <Link to="/career" className="simple_anchor">careers </Link> 
                page for open positions.
              </p>
            </div>
          </div>
        </section>

        <Clutch />
      </Layout>
    )

  }
}

